import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button/button";

const Page = () => {
  return (
      <LessonLayout
        current_step="6a"
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2>Green Coffee Bean Extract Study</H2>

          <p>Use the information in this reading to answer the questions on your handout, <em>Evaluating the Clinical Trials</em>.</p>

          <Button
            action="secondary"
            className="m-2"
            color="purple"
            href="/files/hs/lesson_2/green_coffee_bean_reading-scl.final.aw.pdf"
            icon="download"
            title="Green coffee bean extract study"
          >
            Green coffee bean extract study
          </Button>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
